<template>
<b-card v-if="modules" no-body class="card-company-table">
    <b-table id="my-table" :items="modules" :busy="isBusy" responsive :fields="fields" class="mb-0">
        <template #table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Yükleniyor...</strong>
            </div>
        </template>
    </b-table>
    <b-pagination v-model="page" total-rows="10000" :per-page="100" aria-controls="my-table" @change="getPage"></b-pagination>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BPagination,
    BAvatar,
    BImg,
    BBadge,
    BSpinner
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BPagination,
        BSpinner
    },
    data() {
        return {
            isBusy: false,
            fields: [
                { key: 'code', label: 'Kodu' },
                { key: 'name', label: 'Adı' },
                { key: 'cities', label: 'Şehir' },
                { key: 'faculty', label: 'Fakülte' },
                { key: 'langues', label: 'Dil' },
                { key: 'quota', label: 'Kontenjan' },
                { key: 'scholarship', label: 'Türü' },
                { key: 'basePoint', label: 'Taban Puan' },
                { key: 'topPoint', label: 'Tavan Puan' },
            ],
            modules: [],
            page: 1
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            this.isBusy = true;
            var modules = await this.$http.get("Student/Schools/" + (this.page - 1));
            this.modules = modules.data.data || [];
            this.isBusy = false;
        },
        async getPage(page) {
            //console.log(page);
            //this.page++;
            this.page=page;
            this.getData();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
